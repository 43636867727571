/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import logger from './logger';
console.log('logger',logger);
import vuetify from './vuetify'
import router from '../router'
import store from '@/store'
import { createHead } from '@unhead/vue'

import VueGtag from "vue-gtag";
import configAnalytics from './configAnalytics.js'

console.log(configAnalytics);

const env = import.meta.env;

const head = createHead()
console.log("createHead",head);

export function registerPlugins (app) {
  app
    .use(vuetify)
    .use(store)
    .use(head)
    .use(router)
    .use(VueGtag, configAnalytics)
}

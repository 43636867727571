import apiClient from '@/plugins/apiClient'
let basePath = "cms/admin-project";

export default {
     namespaced: true,
     state:{
          single: null,
          errors: {},
          message: null,
     },
     getters:{
          single: state => state.single,
          message: state => state.message,
          errors: state => state.errors,
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            state.message = err.response.data.message
            state.errors = err.response.data.errors
        },
        setSingle(state, resp){
          console.log('mutation setSingle',resp.data);
          state.single = resp.data
        },
        setProgectUsers(state, resp){
          console.log('mutation setProgectUsers users',resp.data);
          state.single.users = resp.data
        }
     },
     actions:{
          getSingle({commit}){
               return new Promise((resolve, reject) => {
                    console.log('GET Single Project')
                    apiClient.get(basePath).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateSingle({commit}, project){
               return new Promise((resolve, reject) => {
                    console.log('GET Single project:', project)
                    apiClient.patch(basePath+'/update', project).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getUsers({commit}, project){
               return new Promise((resolve, reject) => {
                    console.log('GET Single project users:'+project.id)
                    apiClient.get(basePath+'/get-users').then(resp => {
                         commit('setProgectUsers', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },

          addUser({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADD Single project users:'+data.project_id)
                    apiClient.post(basePath+'/add-user', data).then(resp => {
                         commit('setProgectUsers', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADD SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          removeUser({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('REMOVE Single project users:'+data.project_id)
                    apiClient.post(basePath+'/remove-user', data).then(resp => {
                         commit('setProgectUsers', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('REMOVE SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createSiteMap({commit}){
               return new Promise((resolve, reject) => {
                    console.log('Create Sitemap ')
                    apiClient.post(basePath+'/create-sitemap').then(resp => {
                         // commit('success', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('REMOVE SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setAsSingle({state}, data){
               state.single = data;
          },
          removeErrors({state}){
               state.errors = null;
               state.message = null;
          },
          setMeta({commit}, data){
               console.log('action meta',data);
               commit('setMeta',{meta:data});
               // commit('success', true);
          }
     },
}
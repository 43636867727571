
const env = import.meta.env;

const config = { 
    bootstrap: false,
    config:{
        id: env.VITE_GOOGLE_ANALYTICS_ID
    }
}


export default config;
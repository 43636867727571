import childrenList from "./components/children-list.json"
import heroHeader from "./components/hero-header.json"
import image from "./components/image.json"
import imageLogo from "./components/imageLogo.json"
import mapsPreview from "./components/maps-preview.json"
import spacer from "./components/spacer.json"
import text from "./components/text.json"
import jsonldScript from "./components/jsonldScript.json"
import button from "./components/button.json"
import title from "./components/title.json"
import rowOfContents from "./components/row-of-contents.json"
import simpleSlider from "./components/simple-slider.json"
import cardsLink from "./components/cards-link.json"
import contactForm from "./components/contact-form.json"
import cardImageTitle from "./components/card-image-title.json"
import categoryList from "./components/category-list.json"
import categorySlider from "./components/category-slider.json"
import expansionList from "./components/expansion-list.json"
import heroDetail from "./components/hero-detail.json"
import numericList from "./components/numeric-list.json"
import paginatedTable from "./components/paginated-table.json"
import galleryImages from "./components/gallery-images.json"
import pagesList from "./components/pages-list.json"
import gmapsReviews from "./components/gmaps-reviews.json"
import iconsLinks from "./components/icons-links.json"
import cardButtonLinks from "./components/card-button-links.json"
import fullSection from "./components/full-section.json"
import simpleTable from "./components/simple-table.json"
import video from "./components/video.json"

export default [
    button,
    cardButtonLinks,
    cardImageTitle,
    cardsLink,
    categoryList,
    categorySlider,
    childrenList,
    contactForm,
    expansionList,
    fullSection,
    galleryImages,
    gmapsReviews,
    heroDetail,
    heroHeader,
    iconsLinks,
    image,
    imageLogo,
    jsonldScript,
    mapsPreview,
    numericList,
    paginatedTable,
    pagesList,
    rowOfContents,
    simpleSlider,
    simpleTable,
    spacer,
    text,
    title,
    video
]

// Composables
import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
const env = import.meta.env;

import cmsAuth from './cmsAuth'
import cmsModels from './cmsModels'


const routes = [
  {
    path: '/cms',
    name: 'cms',
    redirect: {name: 'cms.dashboard'},
    component: () => import('@/layouts/dashboard/Default.vue'),
    children : cmsModels,
    meta: {
        auth: true,
    },
  },
  {
    path: '/auth',
    name: 'auth',
    redirect: {name: 'login'},
    component: () => import('@/layouts/dashboard/Default.vue'),
    children: cmsAuth,
  },
  {
    path: '/',
    name: 'start-page',
    redirect: {name: 'primary', params:{slug:localStorage.getItem('locale')}},
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'home',
        redirect: {name: 'primary', params:{slug:localStorage.getItem('locale')}},
        alias: ['home','home-page'],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
      },
      {
        path: '/:slug',
        name: 'page',
        params: ':slug',
        component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Single.vue'),
        children: [
          {
            path: '',
            name: 'primary',
            component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
            children: [
              {
                path: ':slug2',
                name: 'secondary',
                params: ':slug2',
                component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                children: [
                  {
                    path: ':slug3',
                    name: 'tertiary',
                    params: ':slug3',
                    component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                    children: [
                      {
                        path: ':slug4',
                        name: 'quaternary',
                        params: ':slug4',
                        component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                        children: [
                          {
                            path: ':slug5',
                            name: 'fithnary',
                            params: ':slug5',
                            component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },    
        ]
      }
    ],
  }
]

const router = createRouter({
  history: createWebHistory(env.VITE_BASE_URL),
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            {
              el: to.hash,
              behavior: 'smooth',
            }
          )
        }, 200)
      })
    }else{
      return { top: 0 }
    }
  }
});



router.beforeEach( (to, from, next) => {

  let isAuthenticated = store.getters['auth/isAuthenticated']
  let hasSettings = store.getters['settings/hasSettings']

  if(!hasSettings){
    store.dispatch('settings/getSettings');
  }


  // console.log('isAuthenticated', isAuthenticated);
  // console.log('from',from)
  // console.log('to',to)
  // se authenticazione richiesta e non è autenticato vai a login
  if(to.meta){
   if(to.meta.auth){
      if( to.meta.auth==true && !isAuthenticated){
        store.dispatch('snackBars/addSnack', {message: 'Authentication required', type: 'error', err: 'Login'})
          next({name:"login"});
        }
      }
      if(to.meta.auth==false && isAuthenticated){
        store.dispatch('snackBars/addSnack', {message: 'Authenticated user', type: 'success', err: 'Login'})
        next({name:"login"});
      }
      
   }

  document.title = env.VITE_APP_BASE_NAME;

  if(to.fullPath.startsWith("/it/")){
    localStorage.setItem('locale',"it")
  }
  if(to.fullPath.startsWith("/en/")){
    localStorage.setItem('locale',"en")
  }
  if(to.fullPath.startsWith("/de/")){
    localStorage.setItem('locale',"de")
  }


  next();
  
});

export default router
<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    :width="width"
    scrollable
    transition="dialog-bottom-transition"
  >
    <template  v-slot:activator="{ props }">
      <v-btn
        :block="btnBlock"
        :color="btnColor"
        :class="btnClass"
        :size="btnSize"
        dark
        :variant="btnVariant"
        v-bind="props"
      >
        <v-icon v-if="btnIcon" class="me-3">{{btnIcon}}</v-icon>
        {{btnText}}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        dark
        fixed
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{title}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
            <slot :onClose="onClose"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
 name: "DialogCreateUser",
 props:{
   btnText:{
     type:String,
     default: 'commons.open'
   },
   btnVariant:{
     type:String,
     default: 'outlined'
   },
   btnColor:{
      type:String,
      default: 'primary'
   },
   btnIcon:{
     type:String,
     default: null
   },
   btnClass:{
     type:String,
     default: null
   },
   btnSize:{
     type:String,
     default: 'default'
   },
   btnBlock:{
     type: Boolean,
     default: false
   },
   width:{
    type:String,
    default: '100%'
   },
   title:{
     type:String,
     default: 'commons.title'
   },
   remoteCloser:{
    required:false,
    default: false
   },
   remoteOpener:{
    required:false,
    default: false
   }
 },
 data: () => ({
      dialog: false
 }),
 watch:{
    remoteCloser: function(newVal, oldVal) { // watch it
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          this.onClose(false)
    },
    remoteOpener: function(newVal, oldVal) { // watch it
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          this.onClose(true)
    }
 },
 methods:{
  onClose(val) {
        this.dialog = val;
        // eslint-disable-next-line vue/no-mutating-props
        console.log('dialog',val);
  }
 }
}
</script>
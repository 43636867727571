import apiClient from '@/plugins/apiClient'
let basePath = "cms/contact-forms";
import qs from 'qs';

export default {
     namespaced: true,
     state:{
          list: [],
          single: null,
          errors: null,
          message: null,
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          filters: {
               wheres : []
          }
     },
     getters:{
          list: state => state.list,
          single: state => state.single,
          message: state => state.message,
          errors: state => state.errors,
          meta: state => state.meta,
          filters: state => state.filters,
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            state.message = err.response.data.message
            state.errors = err.response.data.errors
        },
        setList(state, resp) {
            console.log('mutation setList',resp);
            state.list = resp.data;
        },
        setMeta(state, resp){
          console.log('mutation setMeta',resp.meta);
          state.meta = resp.meta
        },
        setSingle(state, resp){
          console.log('mutation setSingle',resp.data);
          state.single = resp.data
        },
     },
     actions:{
          getList({commit, state}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET LIST STORES:'+data)
                    var params =  qs.stringify(state.filters, { arrayFormat: 'indices' })
                    apiClient.get(basePath+'/search?page='+state.meta.current_page+'&'+params).then(resp => {
                         commit('setList', resp.data);
                         commit('setMeta', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createSingle({commit}, single){
               return new Promise((resolve, reject) => {
                    console.log('Create Single :'+single)
                    apiClient.post(basePath+'/create', single).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getSingle({commit}, id){
               return new Promise((resolve, reject) => {
                    console.log('GET Single STORE:'+id)
                    apiClient.get(basePath+'/'+id).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateSingle({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET Single ContactForm:', data)
                    apiClient.patch(basePath+'/'+data.id+'/update', data).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          deleteSingle({commit}, id){
               return new Promise((resolve, reject) => {
                    console.log('GET Single Contact Form:', id)
                    apiClient.delete(basePath+'/'+id+'/delete').then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setAsSingle({state}, data){
               state.single = data;
          },
          removeErrors({state}){
               state.errors = null;
               state.message = null;
          },
          setMeta({commit}, data){
               console.log('action meta',data);
               commit('setMeta',{meta:data});
               // commit('success', true);
          }
     },
}
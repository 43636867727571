/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'

import locales from './locales'

// import {
//   // VDataTable,
//   VDataTableServer,
//   VDataTableVirtual,
// } from "vuetify/labs/VDataTable";



// import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  ssr: true,
  components: {
    // VDataTable,
    // VDataTableServer,
    // VDataTableVirtual,
    // VSkeletonLoader,
  },
  locale: {
    adapter: createVueI18nAdapter(locales),
  },
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
          colors: {
            "white": '#FFF',
            "primary": '#ceae57',
            "secondary": '#343a3f',
            "green": "#53a04d",
            "blue": "#1d90a2",
            "red": "#ca4f46",
          },
      },
      light: {
        colors: {
            "white": '#FFF',
            "primary": '#ee6317'
        },
    },
    },
  },
})

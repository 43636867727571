import apiClient from '@/plugins/apiClient'
let basePath = "/registrations/";

export default {
    namespaced: true,
    state:{
        success: null,
        message: null,
        redirect: null,
        errors: {}
    },
    getters:{
        success: state => state.success,
        message: state => state.message,
        errors: state => state.errors,
        redirect: state => state.redirect
    },
    mutations:{
        error(state, err) {
            state.success = false
            console.log('mutation error',err);
            if(err.response){
               state.errors = err.response.data.errors;
               state.message = "Errore di compilazione: "
            }else{
                 state.errors = err
            }
        },
        success(state, data) {
            console.log('mutation success',data);
            
               state.errors = {};
               state.success = true
               state.message = data.message
               state.redirect = data.redirect
           
        },
    },
    actions:{
        create({commit}, data){
            return new Promise((resolve, reject) => {
                 console.log('GET LIST :'+data)
                 apiClient.post(basePath+data.scope.slug, data).then(resp => {
                      commit('success', resp.data);
                      resolve(resp);
                  })
                  .catch(err => {
                      console.log('GET SINGLE', err)
                      commit('error', err);
                      reject(err);
                  });
            })
        },
    }
}
import commons from './it/commons.json'
import attributes from './it/attributes.json'
import models from './it/models.json'
import account from './it/account.json'
import errors from './it/errors.json'
import car from './it/car.json'
import cookie from './it/cookie.json'
import pages from './it/pages.json'
import notifications from './it/notifications.json'
import { it } from 'vuetify/locale'


const lang = {
    $vuetify: it,
    commons: commons,
    attributes: attributes,
    models:models,
    account: account,
    errors: errors,
    cookie:cookie,
    car:car,
    notifications:notifications,
    pages:pages
}

export default lang

import Vuex from 'vuex'
import snackBars from "@/store/modules/snackBars";
import auth from "@/store/modules/auth/";
import loading from "@/store/modules/loading/";
import assets from '@/store/modules/assets/';
import myaccount from '@/store/modules/myaccount';
import AdminPages from '@/store/modules/admin/pages/';
import AdminCategories from '@/store/modules/admin/categories/';
import AdminContactForms from '@/store/modules/admin/contact-forms/';
import AdminContactRegistrations from '@/store/modules/admin/contact-forms/registrations.js';
import Administrator from '@/store/modules/admin/projects/administration.js';
import AdminUsers from '@/store/modules/admin/users/';
import AdminSettings from '@/store/modules/admin/settings/';
import AdminProjects from '@/store/modules/admin/projects/';
import pages from '@/store/modules/pages/';
import categories from '@/store/modules/categories/';
import settings from '@/store/modules/settings/';
import registration from '@/store/modules/registration/';
import cookies from '@/store/modules/cookies/';

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
     cookies,
     snackBars, 
     auth,
     loading,
     assets,
     myaccount,
     pages,
     categories,
     settings,
     registration,
     Administrator,
     AdminPages,
     AdminUsers,
     AdminCategories,
     AdminProjects,
     AdminContactForms,
     AdminContactRegistrations,
     AdminSettings
  }
})
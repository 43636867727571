import apiClient from '@/plugins/apiClient'
let basePath = "/pages";
import qs from 'qs';

export default {
     namespaced: true,
     state:{
          list: [],
          single: null,
          errors: {},
          message: {},
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          filters: {
               wheres : []
          }
     },
     getters:{
          list: state => state.list,
          single: state => state.single,
          message: state => state.message,
          errors: state => state.errors,
          meta: state => state.meta,
          filters: state => state.filters,
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            if(err.response.data){
               state.errors = err.response.data;
               state.errors.status = (err.response.status) ? err.response.status : 500;
               state.message = err.response.data.message;
               state.single = null;
            }else{
               state.errors = err.response
            }
        },
        setList(state, resp) {
            console.log('mutation setList',resp);
            state.list = resp.data
        },
        setMeta(state, resp){
          console.log('mutation setMeta',resp.meta);
          state.meta = resp.meta
        },
        setFilters(state, resp){
          console.log('mutation setFilters',resp.filters);
          state.filters = resp.filters
        },
        setSingle(state, resp){
               console.log('mutation setSingle',resp.data);
               state.single = resp.data
               state.errors = {}
               state.message = {}
        }
     },
     actions:{
          getList({commit, state}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET LIST :'+data)
                    console.log('GET LIST filters:', state.filters)
                    let params =  qs.stringify(state.filters, { arrayFormat: 'indices' })
                    apiClient.get(basePath+'?page='+state.meta.current_page+'&'+params).then(resp => {
                         commit('setList', resp.data);
                         commit('setMeta', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getListLight({commit, state}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET LIST :'+data)
                    console.log('GET LIST filters:', state.filters)
                    let params =  qs.stringify(state.filters, { arrayFormat: 'indices' })
                    apiClient.get(basePath+'/light?page='+state.meta.current_page+'&'+params).then(resp => {
                         commit('setList', resp.data);
                         commit('setMeta', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getSingle({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET Single:'+data.slug)
                    apiClient.get(basePath+data.slug).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp.data);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setAsSingle({state}, data){
               state.single = data;
          },
          setMeta({commit}, data){
               console.log('action meta',data);
               commit('setMeta',{meta:data});
               // commit('success', true);
          },
          setFilters({commit}, data){
               console.log('action setFilters',data);
               commit('setFilters',{filters:data});
          }
     },
}
import commons from './de/commons.json'
import attributes from './de/attributes.json'
import models from './de/models.json'
import account from './de/account.json'
import settings from './de/settings.json'
import errors from './de/errors.json'
import car from './de/car.json'
import checkin from './de/checkin.json'
import cookie from './de/cookie.json'
import orders from './de/orders.json'
import pages from './de/pages.json'
import bookingstepper from './de/bookingstepper.json'
import notifications from './de/notifications.json'

import { de } from 'vuetify/locale'


const lang = {
    $vuetify: de,
    pages: pages,
    commons: commons,
    notifications: notifications,
    attributes: attributes,
    models:models,
    checkin:checkin,
    account: account,
    orders: orders,
    settings: settings,
    errors: errors,
    cookie:cookie,
    car:car,
    bookingstepper: bookingstepper,
}

export default lang
export default [
    {
        path: 'dashboard',
        name: 'cms.dashboard',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Dashboard.vue'),
    },
    {
        path: 'users',
        name: 'cms.users',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Users/List.vue'),
    },
    {
        path: 'users/:id',
        name: 'cms.users.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Users/Single.vue'),
    },
    {
        path: 'assets',
        name: 'cms.assets',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Assets/List.vue'),
    },
    {
        path: 'assets/:id',
        name: 'cms.assets.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Assets/Single.vue'),
    },
    {
        path: 'pages',
        name: 'cms.pages',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Pages/List.vue'),
    },
    {
        path: 'pages/:id',
        name: 'cms.pages.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Pages/Single.vue'),
    },
    {
        path: 'categories',
        name: 'cms.categories',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Categories/List.vue'),
    },
    {
        path: 'categories/:id',
        name: 'cms.categories.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Categories/Single.vue'),
    },
    {
        path: 'contact-forms',
        name: 'cms.contact-forms',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/ContactForms/List.vue'),
    },
    {
        path: 'contact-forms/:id',
        name: 'cms.contact-forms.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/ContactForms/Single.vue'),
    },
    {
        path: 'settings',
        name: 'cms.settings',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Settings/List.vue'),
    },
    {
        path: 'settings/:id',
        name: 'cms.settings.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Settings/Single.vue'),
    },
    {
        path: 'projects',
        name: 'cms.projects',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Projects/List.vue'),
    },
    {
        path: 'projects/:id',
        name: 'cms.projects.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Projects/Single.vue'),
    },
    {
        path: 'administrator',
        name: 'cms.administrator',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Administrator/Administrator.vue'),
    },
];
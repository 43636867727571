import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
        authenticated: (localStorage.getItem('authenticated')=="true"),
        token: localStorage.getItem('access_token'),
        isAdmin: (localStorage.getItem('isAdmin')=="true"),
        isEditor: (localStorage.getItem('isEditor')=="true"),
        isUser: (localStorage.getItem('isUser')=="true"),
        isAdminProject: (localStorage.getItem('isAdminProject')=="true"),
        role: localStorage.getItem('role') ? localStorage.getItem('role') : null,
        errors: {},
        message: null,
        success: null,
     },
     getters:{
        isAuthenticated: state => state.authenticated,
        expire_at: state => state.expire_at,
        errors: state => state.errors,
        message: state => state.message,
        role: state => state.role,
        isAdmin : state => state.isAdmin,
        isAdminProject: state => state.isAdminProject,
        isEditor : state => state.isEditor,
        isUser : state => state.isUser,
        success: state => state.success,
     },
     actions:{
        login({commit},data){
            return new Promise((resolve, reject) => {
                console.log('LOGIN USER',data)
                apiClient.post("auth/login", data).then(resp => {
                        commit('success', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('store auth actions AUTH_LOGIN error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        logout({commit},data){
            return new Promise((resolve, reject) => {
                console.log('LOGOUT USER',data)
                apiClient.post("auth/logout", data).then(resp => {
                        commit('logout', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('store auth actions AUTH_LOGIN error ', err)
                        commit('error', err);
                        commit('logout');
                        reject(err);
                    });
            })
        },
        forgotPassword({commit}, data){
            return new Promise((resolve, reject) => {
                console.log('forgot-password USER',data)
                apiClient.post("auth/forgot-password", data).then(resp => {
                        commit('forgotPassword', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('store auth actions AUTH_LOGIN error ', err)
                        commit('error', err);
                        commit('logout')
                        reject(err);
                    });
            })
        },
        resetPassword({commit}, data){
            return new Promise((resolve, reject) => {
                console.log('forgot-password USER',data)
                apiClient.post("auth/forgot-password/reset", data).then(resp => {
                        commit('resetPassword', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('store auth actions AUTH_LOGIN error ', err)
                        commit('error', err);
                        commit('logout')
                        reject(err);
                    });
            })
        },
        forceLogout({commit}) {
            commit('logout')
        },
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            if(err.response.status === 422){
                state.errors = err.response.data.errors;
            }
            state.message = err.response.data.message;
        },
        success(state,resp) {
            console.log('mutation success',resp);

            state.access_token = resp.access_token
            localStorage.setItem('access_token', resp.access_token)
            localStorage.setItem('locale', resp.language)
            localStorage.setItem('timezone', resp.timezone)
            
            state.authenticated = true;
            localStorage.setItem('authenticated', true)
            
            state.isUser = false
            state.isAdmin = false
            state.isEditor = false
            state.isAdminProject = false
            localStorage.setItem('isAdmin', false)
            localStorage.setItem('isEditor', false)
            localStorage.setItem('isUser', false)
            localStorage.setItem('isAdminProject', false)

            state.role =  resp.role;
            localStorage.setItem('role', resp.role)

            if(resp.role == 'admin'){
                localStorage.setItem('isAdmin', true)
                localStorage.setItem('isEditor', true)
                localStorage.setItem('isUser', true)
                state.isUser = true
                state.isAdmin = true
                state.isEditor = true
                state.isAdminProject = true
            }
            if(resp.role == 'editor'){
                localStorage.setItem('isEditor', true)
                localStorage.setItem('isUser', true)
                state.isUser = true
                state.isEditor = true
            }
            if(resp.role == 'admin_project'){
                localStorage.setItem('isAdminProject', true)
                localStorage.setItem('isEditor', true)
                localStorage.setItem('isUser', true)
                state.isUser = true
                state.isEditor = true
                state.isAdminProject = true
            }
            if(resp.role == 'user'){
                localStorage.setItem('isUser', true)
                state.isUser = true
            }

            state.authenticated = true
        },
        logout(state) {
            console.log('LOGOUT FORCE')
            localStorage.clear()
            state.authenticated = false;
            state.token = '';
        },
        forgotPassword(state, resp){
            console.log('mutation forgotPassword',resp);
            state.success = true;
            state.message = resp.message
        },
        resetPassword(state, resp){
            console.log('mutation resetPassword',resp);
            state.success = true;
            state.message = resp.message
        }
     }
}